import Header from "../Header/Header";
import Footer from "../Footer";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

const AboutPage = () => {
  const language = useContext(Context);

  useEffect(() => {
    language.lang === "eng" ? (document.title = "Announcements") : (document.title = "Duyurular");
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = {
    pageTitle: {
      eng: "Announcements",
      tr: "Duyurular",
    },
    intro: {
      eng: "Announcement Date: 25.02.2025 - The 2nd ordinary general assembly meeting of our association will be held on 13.03.2025 at 14:00 at the association's location if the required majority is achieved, or on 22.03.2025 at the same place and time if the required majority is not achieved. It is announced to our members.",
      tr: "Duyuru Tarihi: 25.02.2025 - Derneğimizin 2. olağan genel kurul toplantısı yeterli çoğunluk sağlandığı takdirde 13.03.2025 tarihinde saat 14:00'de dernek yerleşim yerinde, yeterli çoğunluk sağlanamadığı durumda 22.03.2025 tarihinde aynı yer ve saatte yapılacaktır. Üyelerimize duyurulur.",
    },
  };

  return (
    <main>
      <Header />
      <div className="container half-width-container background-image">
        <div className="intro">
          <h1>{language.lang === "eng" ? content.pageTitle.eng : content.pageTitle.tr}</h1>
          <p>{language.lang === "eng" ? content.intro.eng : content.intro.tr}</p>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default AboutPage;
