import React, { useState, useEffect, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import hamburgerIcon from "../../assets/hamburger.png";
import { Context } from "../../App";
import "./Header.css";

const Header = () => {
  const TRACKING_ID = "G-B0BCR71GN5";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send("pageview");

  const { lang, setLang } = useContext(Context);
  const contentENG = {
    home: "Home",
    about: "About",
    helpus: "Help Us",
    cats: "Cats",
    dogs: "Dogs",
    holdmypaw: "Hold My Paw",
    happytails: "Happy Tails",
    contact: "Contact",
    announcements: "Announcements",
  };
  const contentTR = {
    home: "Ana Sayfa",
    about: "Hakkımızda",
    helpus: "Yardım",
    cats: "Kediler",
    dogs: "Köpekler",
    holdmypaw: "Patimden tut",
    happytails: "Mutlu Patiler",
    contact: "İletişim",
    announcements: "Duyurular",
  };

  const languageController = (e) => {
    setLang(e.target.value);
  };
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const closeNav = () => {
    setNavVisibility(false);
    console.log("closed");
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeNav();
      }
    };

    /* const handleClick = () => {
      if (isNavVisible) {
        closeNav();
      }
    }; */

    window.addEventListener("keydown", handleEsc);
    /* window.addEventListener("click", handleClick); */

    return () => {
      window.removeEventListener("keydown", handleEsc);
      /* window.removeEventListener("click", handleClick); */
    };
  }, []);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="Header">
      <img src={require("../../assets/logo.png")} className="Logo" alt="logo" />
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <Link to="/">{lang === "eng" ? contentENG.home : contentTR.home}</Link>
          <Link to="/about">{lang === "eng" ? contentENG.about : contentTR.about}</Link>
          <Link to="/helpus">{lang === "eng" ? contentENG.helpus : contentTR.helpus}</Link>
          <Link to="/cats">{lang === "eng" ? contentENG.cats : contentTR.cats}</Link>
          <Link to="/dogs">{lang === "eng" ? contentENG.dogs : contentTR.dogs}</Link>
          <Link to="/holdmypaw">{lang === "eng" ? contentENG.holdmypaw : contentTR.holdmypaw}</Link>
          <Link to="/happytails">
            {lang === "eng" ? contentENG.happytails : contentTR.happytails}
          </Link>
          <Link to="/contact">{lang === "eng" ? contentENG.contact : contentTR.contact}</Link>
          <Link to="/announcements">
            {lang === "eng" ? contentENG.announcements : contentTR.announcements}
          </Link>
          <select onChange={languageController} value={lang}>
            <option value="tr">Türkçe</option>
            <option value="eng">English</option>
          </select>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <img src={hamburgerIcon} alt={"menu icon"} />
      </button>
    </header>
  );
};

export default Header;
